<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xl="6">
        <b-card header-tag="header">
          <div slot="header"><i class="icon-star"></i> Starboard settings</div>
          <div v-if="loading">
            <div class="sk-circle-fade float-left">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <h2 class="float-left ml-4">Loading starboard...</h2>
          </div>
          <div v-else>
            <div v-if="has_starboard === true">
              <div>
                <h4 class="smalltitle">Star limit</h4>
                <p>
                  Sets the number of stars required for a message to be posted
                  to the starboard. Between 1 and 25 inclusive.
                </p>
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      type="number"
                      placeholder="3"
                      autocomplete="3"
                      v-model="threshold"
                      min="1"
                      max="25"
                      v-on:keydown="handleNonDecimalInput"
                      maxlength="2"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <hr />
              <h6 class="teenytinytitle">
                Allow NSFW Content
                <c-switch
                  color="danger"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="nsfw"
                  id="nsfw_switch"
                  class="float-right"
                />
              </h6>
              <label
                class="col-form-label"
                for="nsfw_switch"
                style="color:#a8b6bd"
                >Embed images from messages starred in NSFW channels</label
              >
              <hr />
              <h6 class="teenytinytitle">
                Show Jump Link
                <c-switch
                  color="danger"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="show_jump"
                  id="jump_switch"
                  class="float-right"
                />
              </h6>
              <label
                class="col-form-label"
                for="jump_switch"
                style="color:#a8b6bd"
                >Adds a field with a jump link to the original message</label
              >
              <hr />
              <h6 class="teenytinytitle">
                Allow Self Starring
                <c-switch
                  color="danger"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="star_self"
                  id="self_switch"
                  class="float-right"
                />
              </h6>
              <label
                class="col-form-label"
                for="self_switch"
                style="color:#a8b6bd"
                >Counts stars added to members own messages</label
              >
              <hr />
              <h6 class="teenytinytitle">
                Lock Starboard
                <c-switch
                  class="float-right"
                  color="danger"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="locked"
                  id="lock_switch"
                />
              </h6>
              <label
                class="col-form-label"
                for="lock_switch"
                style="color:#a8b6bd"
                >Temporarily disable the starboard</label
              >
              <hr />
              <h6 class="teenytinytitle">
                Automatically Star New Entries
                <c-switch
                  color="danger"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="autostar"
                  :disabled="!this.premium"
                  id="auto_switch"
                  class="float-right"
                />
              </h6>
              <label
                class="col-form-label"
                for="auto_switch"
                style="color:#a8b6bd"
                >The bot will add a star to any fresh entries (premium
                only)</label
              >
              <hr />
              <b-form-group>
                <label for="blacklist" class="teenytinytitle"
                  >Ignore stars in these channels</label
                >
                <multiselect
                  v-model="blacklist"
                  :options="groupedChannels"
                  :multiple="true"
                  group-values="channels"
                  group-label="category"
                  :group-select="false"
                  label="name"
                  track-by="id"
                  id="blacklist"
                >
                  <template slot="tag" slot-scope="{ option, search, remove }">
                    <span
                      class="multiselect__tag channelpicker__tag"
                      :style="{ borderColor: '#eee' }"
                    >
                      <i
                        aria-hidden="true"
                        tabindex="1"
                        @keydown.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon channelpicker_icon"
                        :style="{ backgroundColor: '#eee' }"
                      ></i>
                      <font-awesome-icon
                        style="color:#999;margin-right:3px;"
                        icon="hashtag"
                      />
                      <span class="defaultcursor" v-text="option.name"></span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <font-awesome-icon
                          style="color:#72767d"
                          :icon="
                            !props.option['$isLabel']
                              ? 'hashtag'
                              : 'chevron-down'
                          "
                        />
                        {{ props.option.name || props.option.$groupLabel.name }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <div slot="footer" v-if="has_starboard">
                <b-button
                  class="float-right"
                  variant="primary"
                  v-on:click="patch_starboard"
                  >Save</b-button
                >
              </div>
            </div>
            <div v-else>
              <h4 class="smalltitle">
                No starboard found
                <font-awesome-icon
                  style="color:#888"
                  :icon="['fas', 'question-circle']"
                  class="mx-2"
                  id="restricted_question"
                />
                <b-popover
                  triggers="hover"
                  placement="top"
                  target="restricted_question"
                >
                  <h5 class="tinytitle">What is a Starboard?</h5>
                  It's like democratic pins. After you make a Starboard channel,
                  users can react to a message with a ⭐ to 'vote' to display
                  that post on the starboard. Once the message gets enough ⭐
                  reactions, Carlbot posts it onto the starboard. Give it a try!
                  I'm sure your server will like it.
                </b-popover>
              </h4>
              <h6 class="teenytinytitle">
                You can create a starboard by choosing a name for the channel
                you want created
              </h6>
              <b-form-group>
                <b-input-group>
                  <div class="input-group-prepend">
                    <div class="input-group-text">#</div>
                  </div>
                  <b-form-input
                    type="text"
                    pattern="[a-z0-9-_]{1,32}"
                    v-model="starboard_name"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button v-on:click="create_starboard" variant="primary"
                      >Create</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
    Multiselect
  },
  data: function() {
    return {
      selected: "Month",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      nsfw: false,
      star_self: false,
      show_jump: true,
      locked: false,
      autostar: false,
      premium: false,
      threshold: 3,
      channels: [],
      blacklist: [],
      loading: true,

      has_starboard: true,
      starboard_name: "starboard"
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    },
    showInfoMsg: {
      type: VueNotifications.types.info,
      title: "No changes",
      message: "That's your current star limit."
    },
    showWarnMsg: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "Pick a number between 1 and 25."
    },
    showErrorMsg: {
      type: VueNotifications.types.error,
      title: "Uh-oh",
      message: "Pick a number between 1 and 25."
    }
  },
  methods: {
    patch_starboard() {
      if (this.threshold < 1 || this.threshold > 25) {
        return this.showErrorMsg();
      }
      this.axios
        .patch(
          "/api/v1/servers/" + this.$route.params.guild_id + "/starboard",
          {
            nsfw: this.nsfw,
            star_self: this.star_self,
            threshold: this.threshold,
            show_jump: this.show_jump,
            locked: this.locked,
            autostar: this.autostar,
            blacklist: this.blacklist.map(x => x.id) || []
          }
        )
        .then(this.showSuccessMsg());
    },
    handlethreshold(v) {
      if (
        !(this.threshold < 1 || this.threshold > 25) &&
        this.threshold != this.current_threshold
      ) {
        this.current_threshold = this.threshold;
        this.handleChange("threshold", this.threshold);
      } else if (this.threshold == this.current_threshold) {
        this.showInfoMsg();
      } else {
        this.showWarnMsg();
      }
    },
    handleChange(e, v) {
      this.axios
        .patch(
          "/api/v1/servers/" + this.$route.params.guild_id + "/starboard",
          { [e]: v }
        )
        .then(this.showSuccessMsg());
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    create_starboard() {
      this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/starboard", {
          name: this.starboard_name,
          author: this.$store.state.discord_user.id
        })
        .then(() => {
          this.has_starboard = true;
        });
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/starboard")
      .then(r => {
        if (Object.keys(r.data).length === 0) {
          this.has_starboard = false;
        } else {
          this.nsfw = r.data.nsfw;
          this.star_self = r.data.star_self;
          this.show_jump = r.data.show_jump;
          this.threshold = r.data.threshold;
          this.autostar = r.data.autostar;
          this.locked = r.data.locked;
          this.current_threshold = r.data.threshold;
          this.channels = r.data.channels;
          this.blacklist = r.data.blacklist;
          this.premium = r.data.premium;
        }
      });
  },
  created() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/starboard")
      .then(r => {
        this.loading = false;
        if (Object.keys(r.data).length === 2) {
          this.has_starboard = false;
        } else {
          this.nsfw = r.data.nsfw;
          this.star_self = r.data.star_self;
          this.show_jump = r.data.show_jump;
          this.threshold = r.data.threshold;
          this.autostar = r.data.autostar;
          this.locked = r.data.locked;
          this.current_threshold = r.data.threshold;
          this.channels = r.data.channels;
          this.premium = r.data.premium;
          this.blacklist = this.channels.filter(c =>
            (r.data.blacklist || []).includes(c.id)
          );
        }
        this.$Progress.finish();
      });
  },
  computed: {
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  }
};
</script>
